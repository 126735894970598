.video_Card {
  width: 200px;
  height: 300px;
}

.video-Radius {
  border-radius: 10px;
}

.VideoActionButton {
  margin-top: 10px;

  // icon

  & .VideoActionButton__icon {
    font-size: 22px;
    color: #fff;
  }

  // content

  & .VideoActionButton__content {
    font-size: 12px;
    font-weight: 500;
    display: block;
    color: #fff;
    margin-top: 5px;
  }
}
