
@media screen and (max-width: 600px) {

    .credit_card_entire_page{
        margin: 0 !important;
        max-width: 100%;
        height: 100vh;
        display: flex;
        border:  none !important;
        justify-content: none !important ;
        align-items: none !important ;
        background-image: none !important;
        background-repeat: no-repeat; 
        background-size: cover;
    }
    
    .the_bigger_container{
        margin-top: 20px;
        /* margin-left: 50px; */
        /* background-color: red; */
        /* width:600px; */
        /* width:550px; */
        max-width: fit-content !important;
        /* border: 2px solid rgb(169, 197, 169); */
        /* height:800px; */
        border-radius: 5px;
        justify-content: none !important;
        display: flex;
        flex-direction: none !important;
        align-items: none !important;
    }

    .the_container_after_the_bigger_container{
        /* width:700px; */
        width: fit-content !important;
        /* width:550px; */
        max-width: 100% !important;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between !important;

        align-items: none;
        box-shadow: 0 10px 16px rgba(0, 0, 0, .05);
        /* padding: 15px 36px; */
        /* padding-top: ;
        padding-bottom: ;
        padding-right: ;
        padding-left: ; */

    }


    .logo_and_candidate{
        display:flex !important;
        width:max-content!important;
        align-items: center !important;
        justify-content: space-between !important;
        gap: 30px;
        /* border: 2px solid red; */
    }

    .Payment_container{
        width: fit-content!important;
        max-width: 100%;
        border-radius: 10px;
        display:flex;
        flex-direction:column;
        align-items: center;
        overflow: hidden;
        padding-bottom: 0px;
    }

    .form_chapeau{
        background-color: #134E5E;
        width: 100%;
        height: 30px !important;
        display:flex;
        justify-content: space-around !important;
        flex-direction:row;
        padding: 0 25px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .text_pt1{
        font-size: 10px !important;
        font-weight: 500 !important;
        color: white !important;
        display:flex;
        justify-content: center;
        align-items : center;
        color: white;
        font-size: 18px;
        font-weight: 600;
    }
    .text_pt2{
        color: #3C4043;
        font-weight: 700;

    }
    .input_its_title .text_pt2{
        font-weight: 700 !important;
    }
    .Credit_Card_Info{
        width: 100% !important;
        display:flex;
        justify-content: center;
        align-items : center;
        padding: 30px 20px;
        border: 2px solid #c4c4c4;
        border-top: 0;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .inner_Credit_Card_Info{
        width: 100%;
        display:flex;
        justify-content: start;
        flex-direction: column;
        row-gap: 20px;
    }

    .input_styleed{
        border-radius: 10px;
        width: 100% !important;
    }

    .single_input{
        height: 55px;
        width: 100%;
        border: 1px solid #c4c4c4;
        padding: 0 15px;
        
    }

    .couple_input{
        height: 55px;
        border: 1px solid #c4c4c4;
        padding: 0 15px;
    }
    .input_its_title .text_pt2{
        font-weight: 700;
    }
    p {
        margin: 0;
    }
    .input_its_title{
        width: 100%;
        display: flex;
        justify-content: start;
        flex-direction: column;
    }
    .put_a_margin_bottom{
        margin-top: 20px;
    }

    .double_input_its_title{
        height: 40px !important;
        flex-direction: column !important;
        width: 100%;
        height: 160px !important;
        display: flex;
        /* justify-content: space-between; */
    }

    .terms_conditions_part{
        width: 100%;
        /* height: 60px; */
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        /* border-color: 1px solid red; */
    }

    .Pay_button_area{
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
    }

    .Pay_button{
        height: 60px;
        width: 100%;
        border-radius: 10px;
        background-color: #134E5E;
        color: white;
        font-size: 18px;
    }

    .Payment_Logos{
        display:flex;
        flex-direction:row;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }

    .logos_pt1{
        height: auto;
        width: 40px;
    }

    .payments_pictures{
        width: 450px;
        /* height: 150px; */
    }

    .log_picture{

        border-radius: 10px;
        width: 90px !important;
        height: 60px !important;
        margin-left: 10px;
    }

    .text_pc{
        display: flex;
        justify-content: center;
        font-family: 'regular', sans-serif;
        font-weight: normal;
        font-size: 20px;
        color: #134E5E;
    }

    .terms_policies_color{
        color: #134E5E;
    }
    .language_button{
        /* float: left !important; */
    }
    .grouped_language_button{
        float: center !important;
        /* border: 2px solid red; */
        /* justify-content: center !important; */
    }
    .return_button_field{
        display: none !important;
        justify-content: left;
        width: 550px;
        border: 2px solid blue;
    }
    .return_button{
        display: none;
    }
}