

    /* Mobile Styles for width less than 600px */
@media (max-width: 600px) {
    .cashplus_credit_card_entire_page {
        max-width: 100vw;
        height: fit-content !important;
        min-height: 100vh !important;
        height: fit-content;
        display: flex;
        justify-content: start;
        align-items: start;
        background-color: #134E5E !important;
        /* background-color: #134E5E; */
        background-image: none !important;
        background-repeat: no-repeat; 
        background-size: cover;
        padding-bottom: 0 !important;
        margin: 0 !important;
        
    }
    .return_button{
        display: none;
    }
    .cashplus_the_bigger_container {
        width: 100vw; /* Make it responsive for smaller screens */
        max-height: 100vh !important;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
        background-color: #134E5E;
        max-width: 100vh !important;
        padding: 0px !important;
        margin: 0 !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        
    }
    
    .cashplus_the_container_after_the_bigger_container {
        max-width: 95% !important; 
        height: fit-content !important; 
        border-radius: 5px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        box-shadow: 0 10px 16px rgba(0, 0, 0, .05);
        padding: 0 0 !important; /* Reduce padding */
        margin: 0 0 !important; /* Adjust margins for mobile */
        /* width: 100%; */
        padding: 0px !important;
        margin: 0 !important;
        /* border: 2px solid pink; */
        /* background-color: aquamarine; */
    }
    
    .cashplus_logo_and_candidate {
        /* border: 2px solid green; */
        flex-direction: column;
        align-items: center;
    }

    .cashplus_form {
        font-size: 20px; /* Decrease font size */
        margin:15px 15px; /* Adjust margins for mobile */
        font-weight: 600;
        
    }

    .cashplus_payment_buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .cashplus_Payment_button {
        width: 120px; /* Decrease button size */
        height: 80px;
        border-radius: 15px; /* Adjust radius */
    }

    .cashplus_Payment_button:hover {
        width: 120px;
        height: 80px;
        border-radius: 15px;
        border: 2px solid #134E5E;
        cursor: pointer;
    }

    .cashplus_text_payment_methods {
        width: 100%;
        margin-top: 20px;
        font-size: 9px; /* Decrease font size */
        text-align: left;
        color: grey;
    }

    .cashplus_text_payment_methods:hover {
        color: black;
    }

    .cashplus_intrudaction_text {
        width: 100%;
        font-size: 16px; /* Adjust font size for mobile */
        font-weight: 600;
        text-align: center;
    }
    .p{
        font-weight: 800;

    }
    .cashplus_token {
        font-size: 28px; /* Adjust font size */
        font-weight: 700;
        margin: 15px;
    }
    
    .cashplus_instruction_title {
        font-size: 18px; /* Adjust font size */
        font-weight: 500;
        text-align: left;
    }
    
    .cashplus_instruction_text {
        text-align: left;
        font-weight: 300;
        padding-left: 14px; /* Adjust padding */
    }
}

  