.text_ista{
    margin-bottom: 20px;
    font-size: 23px;

}

.thank_credit_card_entire_page{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #134E5E;
    /* background-image: url('https://kick-off.me/image/ad-photo.jpeg'); */
    background-image: url('https://static.vecteezy.com/system/resources/previews/011/628/499/large_2x/soccer-players-team-free-photo.jpg');
    background-repeat: no-repeat; 
    background-size: cover;
}

.thank_the_bigger_container{
    width:650px;
    /* height:800px; */
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    background-color: #134E5E;
    /* padding: 100px 36px; */
}

.thank_the_container_after_the_bigger_container{
    width:550px;
    /* height: 700px; */
    max-width: 90%;
    border-radius: 5px;
    /* background-color: red; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    box-shadow: 0 10px 16px rgba(0, 0, 0, .05);
    padding: 15px 36px;
    margin: 50px 5px;
    /* padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 36px;
    padding-right: 36px; */
    /* padding-top: ;
    padding-bottom: ;
    padding-right: ;
    padding-left: ; */
}


.thank_logo_and_candidate{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.thank_form{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin: 0 25px;
}

