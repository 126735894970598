.selection_credit_card_entire_page{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #134E5E;
    /* background-image: url('https://kick-off.me/image/ad-photo.jpeg'); */
    background-image: url('https://images.pexels.com/photos/61143/pexels-photo-61143.jpeg');
    background-repeat: no-repeat; 
    background-size: cover;
}

.selection_the_bigger_container{
    width:650px;
    /* height:800px; */
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    background-color: #134E5E;
    /* background-color: #134E5E; */
    /* padding: 100px 36px; */
}

.selection_the_container_after_the_bigger_container{
    width:550px;
    /* height: 700px; */
    max-width: 90%;
    border-radius: 5px;
    /* background-color: red; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    box-shadow: 0 10px 16px rgba(0, 0, 0, .05);
    padding: 15px 36px;
    margin: 50px 5px;
    /* padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 36px;
    padding-right: 36px; */
    /* padding-top: ;
    padding-bottom: ;
    padding-right: ;
    padding-left: ; */
}


.selection_logo_and_candidate{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.selection_form{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin: 0 25px;
}

.selection_payment_buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.selection_Payment_button{
    width: 150px;
    height: 100px;
    border-radius: 20px;
}

.selection_Payment_button:hover{
    width: 150px;
    height: 100px;
    border-radius: 20px;
    border: 2px solid #134E5E;
    cursor: pointer;

}

.selection_text_payment_methods{
    width: 100%;
    margin-top: 30px;
    font-size: 10px;
    text-align: left;
    color: grey;
}

.selection_text_payment_methods:hover{
    /* width: 100%;
    margin-top: 30px;
    font-size: 10px;
    text-align: left; */
    color: black;
}

.selection_Payment_Buttons_choice{
    width: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: space-around;
}

.selection_Payment_Buttons_choice_click{
    padding: 10px 10px;
    border-radius: 10px;
    background-color: #134E5E;
    color: white;
}

.selection_Payment_Buttons_choice_click:hover{
    cursor: pointer;
}






.payment_landing_page_css{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This makes the container take up the full viewport height */
    width: 100vw; /* This makes the container take up the full viewport width */
    flex-direction: column;
}


.felecitation_css{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 500px;
    width: 700px;
    background-color: #6d28d9; 
    flex-direction: column;

}


.khaless_button_css{
    width: 100%;
    height: 100%;
}

.khaless_button_area_css{
    width: 100%;
    height: 50px;
    border:solid 1px red;
    display: flex;
    justify-content: end;
}

.error_message{
    color: red;
}


.guide_message{
    color: grey;
}



/* Default CSS */

/* Mobile Specific CSS */
@media (max-width: 600px) {
    .selection_credit_card_entire_page{
      width: 100vw;
      /* height: 100vh; */
      max-height: 100vh;
      background-size: cover;
      background-image: none; /* Remove background image on mobile */
      /* background-color: #134E5E; */
       /* Ensure background color remains */
       padding: 0px !important;
       margin: 0 !important;
       border: 1px solid pink;
    }
    
    .selection_the_bigger_container {
        width: 100vh !important;
        padding: 0px !important;
        margin: 0 !important;
        /* padding: 10px; */
    }
    
    .selection_the_container_after_the_bigger_container {
      width: 100%;
      padding: 0px !important;
      margin: 0 !important;
    }
  
    .selection_logo_and_candidate {
      flex-direction: column;
      align-items: center;
      /* margin-bottom: 20px; */
    }
  
    .selection_form {
      font-size: 16px;
      margin: 0 15px;
    }
  
    .selection_payment_buttons {
      flex-direction: column;
      align-items: stretch;
    }
  
    .selection_Payment_button {
      width: 100%;
      height: 80px;
      margin-bottom: 10px;
    }
  
    .selection_Payment_button:hover {
      width: 100%;
      height: 80px;
    }
  
    .selection_text_payment_methods {
      font-size: 12px;
      text-align: center;
      margin-top: 20px;
    }
  
    .selection_Payment_Buttons_choice {
      flex-direction: column;
    }
  
    .selection_Payment_Buttons_choice_click {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .payment_landing_page_css {
      padding: 20px;
    }
  
    .felecitation_css {
      height: auto;
      width: 90%;
    }
  
    .khaless_button_area_css {
      justify-content: center;
    }
  
    .khaless_button_css {
      height: 40px;
    }
  
    .error_message {
      font-size: 14px;
    }
  
    .guide_message {
      font-size: 14px;
    }
  }
  