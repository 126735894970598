*{
    outline: 0 !important;
}



.credit_card_entire_page{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #134E5E;
    /* background-image: url('https://kick-off.me/image/ad-photo.jpeg'); */
    background-image: url('https://images.pexels.com/photos/61143/pexels-photo-61143.jpeg');
    background-repeat: no-repeat; 
    background-size: cover;
}

.the_bigger_container{
    width:700px;
    /* width:550px; */

    /* height:800px; */
    border-radius: 5px;
    justify-content: center;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.the_container_after_the_bigger_container{
    /* width:700px; */
    width:550px;
    max-width: 90%;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    box-shadow: 0 10px 16px rgba(0, 0, 0, .05);
    padding: 15px 36px;
    /* padding-top: ;
    padding-bottom: ;
    padding-right: ;
    padding-left: ; */
}


.logo_and_candidate{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

}

.Payment_container{
    width: 100%;
    border-radius: 10px;
    display:flex;
    flex-direction:column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 30px;
}

.form_chapeau{
    background-color: #134E5E;
    width: 100%;
    height: 60px;
    display:flex;
    justify-content: space-between;
    flex-direction:row;
    padding: 0 25px;

}
.text_pt1{
    display:flex;
    justify-content: center;
    align-items : center;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
.text_pt2{
    color: #3C4043;
    font-weight: 700;

}
.input_its_title .text_pt2{
    font-weight: 700;
}
.Credit_Card_Info{
    width: 100%;
    display:flex;
    justify-content: center;
    align-items : center;
    padding: 30px 20px;
    border: 2px solid #c4c4c4;
    border-top: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

}

.inner_Credit_Card_Info{
    width: 100%;
    display:flex;
    justify-content: start;
    flex-direction: column;
    row-gap: 20px;
}

.input_styleed{
    border-radius: 10px;
}

.single_input{
    height: 55px;
    width: 100%;
    border: 1px solid #c4c4c4;
    padding: 0 15px;
    
}

.couple_input{
    height: 55px;
    border: 1px solid #c4c4c4;
    padding: 0 15px;
    
}
.input_its_title .text_pt2{
    font-weight: 700;
}
p {
    margin: 0;
  }
.input_its_title{
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
}
.put_a_margin_bottom{
    margin-top: 20px;
}

.double_input_its_title{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    
}

.terms_conditions_part{
    width: 100%;
    /* height: 60px; */
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    /* border-color: 1px solid red; */
}

.Pay_button_area{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.Pay_button{
    height: 60px;
    width: 100%;
    border-radius: 10px;
  background-color: #134E5E;
  color: white;
  font-size: 18px;
}

.Payment_Logos{
    display:flex;
    flex-direction:row;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.logos_pt1{
    height: auto;
    width: 40px;
}

.payments_pictures{
    width: 450px;
    height: 150px;
}

.log_picture{
    width: 160px;
    height: 90px;

    border-radius: 10px;
}

.Congratulations{
  
}

.text_pc{
    display: flex;
    justify-content: center;
    font-family: 'regular', sans-serif;
    font-weight: normal;
    font-size: 20px;
    color: #134E5E;
}
.terms_policies_color{
    color: #134E5E;

}

.return_button_field{
    display: flex;
    justify-content: left;
    width: 550px;

}

.background_green{
    background-color: #134E5E;
}

.grouped_language_button{
    float: right !important;
    justify-content: center !important;
    flex-Direction: row;
}












.payment_choices{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
}


.red{
    border-style: solid;
    border-width: 2px;
    border-color: red;
}
.yellow{
    border-style: solid;
    border-width: 2px;
    border-color: yellow;
}
.blue{
    border-style: solid;
    border-width: 2px;
    border-color: blue;
}
.green{
    border-style: solid;
    border-width: 2px;
    border-color: green;
}
.pink{
    border-style: solid;
    border-width: 2px;
    border-color: pink;
}
.black{
    border-style: solid;
    border-width: 2px;
    border-color: black;
}



.payment_choices{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
}

/* لأنها خدمة دفع أقرب للمغاربة CashPlus نحن نوفر دفع الرسوم المطلوبة باستخدام  */

.creditcard_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.third_lane{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
    padding-top: 20px;
}

.input_payment_css{
    width: 350px;
}

.inner_third_lane
{
    display: flex;
    flex-direction: column;
}

.pad-top{
    padding-top: 20px;
}



.group_info{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.hafl_input{
    width: 100%;
    /* margin-top: 10px; */

}

.payment_button{
    width: 80%;
    height: 40px;
    background-color: blue;
    border-radius: 3px;
    color: white;
    font-size: 16px;
}

.payment_button_area{
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Centers children horizontally */
    align-items: center; 
}

.payment_button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

.payment_by_choice_input_data{
    display: flex;
    justify-content: center; /* Centers children horizontally */
    align-items: center; 
}


.image_css{
    width: 80%;
    height: 100px;
}

.image_position{
    display: flex;
    justify-content: center;
}

.pilicies_text{
    font-size: 11px;
    color: black;
    padding-left: 10px;
}

.terms_policies_color:hover{
    border-bottom: 1px solid #134E5E;
    /* box-shadow: 0px 0px 6px rgba(19, 78, 94, 0.5); */
    /* filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5)); */
    cursor: pointer;
}
.cursor_pointer_hover:hover{
    cursor: pointer;
}



.return_button{
    /* margin-top: 30px; */
    height: 60px;
    width: 120px;
    border-radius: 10px;
  /* background-color: #134E5E; */
  /* background-image: url('/assets/images/left-arrow.png'); */
  background-Size: 'cover';
  background-Position: 'center';
  background-Repeat: 'no-repeat';
  color: white;
  font-size: 18px;
}


.language_button
{
    font-Size: 16px;
    font-Weight: 300;
    border-Bottom:1px solid #134E5E;
    margin-Right: 20px;
    color: #134E5E;
    cursor:pointer;

}