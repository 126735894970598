.cashplus_credit_card_entire_page{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #134E5E;
    /* background-image: url('https://kick-off.me/image/ad-photo.jpeg'); */
    background-image: url('https://images.pexels.com/photos/61143/pexels-photo-61143.jpeg');
    background-repeat: no-repeat; 
    background-size: cover;
}

.cashplus_the_bigger_container{
    width:650px;
    /* height:800px; */
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    background-color: #134E5E;
    /* padding: 100px 36px; */
}

.cashplus_the_container_after_the_bigger_container{
    width:550px;
    /* height: 700px; */
    max-width: 90%;
    border-radius: 5px;
    /* background-color: red; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    box-shadow: 0 10px 16px rgba(0, 0, 0, .05);
    padding: 15px 36px;
    margin: 50px 5px;
    /* padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 36px;
    padding-right: 36px; */
    /* padding-top: ;
    padding-bottom: ;
    padding-right: ;
    padding-left: ; */
}


.cashplus_logo_and_candidate{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.cashplus_form{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin: 0 25px;
}

.cashplus_payment_buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.cashplus_Payment_button{
    width: 150px;
    height: 100px;
    border-radius: 20px;
}

.cashplus_Payment_button:hover{
    width: 150px;
    height: 100px;
    border-radius: 20px;
    border: 2px solid #134E5E;
    cursor: pointer;

}

.cashplus_text_payment_methods{
    width: 100%;
    margin-top: 30px;
    font-size: 10px;
    text-align: left;
    color: grey;
}

.cashplus_text_payment_methods:hover{
    /* width: 100%;
    margin-top: 30px;
    font-size: 10px;
    text-align: left; */
    color: black;
}

.cashplus_intrudaction_text{
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.cashplus_token{
    font-size: 28px;
    font-weight: 500;
    margin: 20px;
}

.cashplus_instruction_title{
    /* font-size: 28px; */
    width: 100%;
    font-weight: 400;
    text-align: left;
}

.cashplus_instruction_text{
    text-align: left;
    padding-left: 20px;
}