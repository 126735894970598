
@media (max-width: 600px) {
    .selection_credit_card_entire_page{
      width: 100vw;
      /* height: 100vh; */
      max-height: 100vh !important;
      background-size: cover;
      background-image: none;
      /* background-color: #134E5E; */
       /* Ensure background color remains */
       padding: 0px !important;
       margin: 0 !important;
       border: 1px solid pink;
    }
    
    .selection_the_bigger_container {
        width: 100vh !important;
        padding: 0px !important;
        margin: 0 !important;
        /* padding: 10px; */
    }
    
    .selection_the_container_after_the_bigger_container {
      width: 100%;
      padding: 0px !important;
      margin: 0 !important;
    }
  
    .selection_logo_and_candidate {
      flex-direction: column;
      align-items: center;
      /* margin-bottom: 20px; */
    }
  
    .selection_form {
      font-size: 16px;
      margin: 0 15px;
    }
  
    .selection_payment_buttons {
      flex-direction: column;
      align-items: stretch;
    }
  
    .selection_Payment_button {
      width: 100%;
      height: 80px;
      margin-bottom: 10px;
    }
  
    .selection_Payment_button:hover {
      width: 100%;
      height: 80px;
    }
  
    .selection_text_payment_methods {
      font-size: 12px;
      text-align: center;
      margin-top: 20px;
    }
  
    .selection_Payment_Buttons_choice {
      flex-direction: column;
    }
  
    .selection_Payment_Buttons_choice_click {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .payment_landing_page_css {
      padding: 20px;
    }
  
    .felecitation_css {
      height: auto;
      width: 90%;
    }
  
    .khaless_button_area_css {
      justify-content: center;
    }
  
    .khaless_button_css {
      height: 40px;
    }
  
    .error_message {
      font-size: 14px;
    }
  
    .guide_message {
      font-size: 14px;
    }
  }
  