.videoPage {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.videoPage__card {
  max-height: 300px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden !important;
  box-shadow: 0 10px 16px 0 rgba($color: #000000, $alpha: 0.03);

}
.videoPage__card__content{
  width: 100%;
  padding: 20px 15px;
  display: flex;
}
.videoPage__card__content__btn{
  width:130px;
}
@media  (max-width: 700px) {
  .videoPage {
    grid-template-columns: repeat(2, 1fr);
  }
}

.video__user {
  position: absolute;
  top: 10px;
  left: 10px;
}

.video_btns {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-right: 15px;
}

.btn {
  margin-top: 5px;
  //   margin-right: 15px;
}

.vjs-icon-placeholder.vjs-icon-placeholder {
  color: #fff;
}

.videoPage__pagination {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
