.login {
  width: 100%;
  height: 100vh;
  background-color: #134e5e21;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.login_card {
  width: 400px;
  min-height: 300px;
  border-radius: 10px;
}

.login__password {
  margin-top: 15px;
}

.login__button {
  margin-top: 15px;
}

.login__logo {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .login_card {
    width: 100%;
  }
}
